import * as ROUTES from "../../constants/routes";
const serverUrl = "https://app.longhornhsa.org"

export default class f {
  static check(route, options) {
    debugger;
    var url = serverUrl + route
    return fetch(url, options).then(response => {
      if (response.status === 401) {
        debugger;
        window.location.href = ROUTES.LANDING;
      }
      return response;
    });
  }

}
