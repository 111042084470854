import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import * as firebase from "firebase";
import "./booth.css";
import f from "../Session/fetchWrapper";
import { motion } from "framer-motion";
const width = 300;
class BoothPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.event,
      booths: []
    };
    // this.getBooths = this.getBooths.bind(this);
  }
  componentDidMount() {
    // this.getBooths();
  }

  componentWillUnmount() {
  }

  //  async getBooths() {
  //   this.setState({ loading: true });
  //   const { event } = this.state;
  //   await f.check("http://localhost:5000/events/", {
  //     method: "POST",
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/JSON"
  //     },
  //     body: JSON.stringify({ event: event })
  //   }).then(response => {
  //     var booths = [];
  //     var numBooths = 0;
  //     response
  //       .json()
  //       .then(data => {
  //         debugger;
  //         booths = data.booths;
  //         console.log(booths);
  //         for (var i = 0; i < booths.length; i++) {
  //           numBooths++;
  //         }
  //       })
  //       .then(() => {
  //         debugger;
  //         this.setState({
  //           booths: booths,
  //           loading: false,
  //           numBooths: numBooths
  //         });
  //       })
  //   });
  // }
  newBooth = () => {
    const { event } = this.state;
    f.check("/events/booth/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      },
      body: JSON.stringify({ event: event })
    }).then(response => {
      debugger;
      response.json().then(data => {
        this.forceUpdate();
      });
    });
  };

  // deleteBooth = () => {
  //   debugger;
  //     var boothNum = this.state.numBooths - 1;
  //     const { event } = this.state;
  //   f.check('http://localhost:5000/events/booth/delete', {
  //     method: 'POST',
  //     credentials: 'include',
  //     headers: {
  //       'Content-Type': 'application/JSON',

  //     },
  //     body: JSON.stringify({event: event, boothNum: boothNum})
  //   }).then(response => {
  //     debugger;
  //     response.json()
  //       .then((data) => {
  //         this.getBooths();

  //       })
  //     })
  // }

  render() {
    const { booths, loading, eventName } = this.state;
    return (

      <div>
        {/* <motion.div     
          animate={{
          opacity:[0,.5,1,1],
          // scale: [0, 1, 1.1, 1],
          y: [100, -5, 5, 0],
          }}    
          class="boothContainer">
          <h1>{this.props.eventName}</h1>
          </motion.div> */}
        {loading && <div></div>}
        <div>
          <BoothWrapper event={this.props.event} />
          <div></div>
        </div>
      </div>
    );
  }
}

export class BoothWrapper extends Component {
  constructor(props) {
    debugger;
    super(props);
    this.state = {
      numBooths: 0,
      booths: [],
      event: this.props.event
    };
    this.deleteBooth = this.deleteBooth.bind(this);
  }

  componentDidMount() {
    this.getBooths();
  }

  componentWillUpdate(prevProps) {
    debugger;
  }

  async getBooths() {
    this.setState({ loading: true });
    const { event } = this.state;
    await f.check("/events/", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/JSON"
        },
        body: JSON.stringify({ event: event })
      })
      .then(response => {
        var booths = [];
        var numBooths = 0;
        response
          .json()
          .then(data => {
            debugger;
            booths = data.booths;
            console.log(booths);
            for (var i = 0; i < booths.length; i++) {
              numBooths++;
            }
          })
          .then(() => {
            this.createBooths(booths);
          });
      });
  }

  createBooths(booths) {
    debugger;
    let boothComponents = [];
    var numBooths = 0;
    for (var i = 0; i < booths.length; i++) {
      debugger;
      boothComponents.push(
        <Booth
          booth={booths[i]}
          deleteBooth={this.deleteBooth}
          event={this.props.event}
        />
      );
      debugger;
    }
    this.setState({
      booths: boothComponents,
      loading: false,
      numBooths: numBooths
    });
  }

  newBooth = () => {
    debugger;
    const { event } = this.state;
    debugger;
    f.check("/events/booth/create", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      },
      body: JSON.stringify({ event: event })
    }).then(response => {
      debugger;
      response.json().then(data => {
        this.getBooths();
      });
    });
  };

  deleteBooth = (title, description, code, index, event, id) => {
    debugger;
    console.log(id);
    f.check("/events/booth/delete", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      },
      body: JSON.stringify({
        event: event,
        index: index,
        code: code,
        title: title,
        description: description,
        id: id
      })
    }).then(response => {
      debugger;
      response.json().then(data => {
        this.getBooths();
      });
    });
  };

  render() {
    debugger;
    return (
      <div>
        {this.state.loading ? (
          <div class="boothsContainer">{this.state.booths}</div>
        ) : (
          <motion.div
            animate={{
              opacity: [0, 0.5, 1, 1],
              scale: [0, 1, 1, 1],
              y: [100, -10, 5, 0]
            }}
            transition={{
              duration: 0.6,
              ease: "easeInOut",
              times: [0, 0.6, 0.8, 1]
            }}
          >
            <div class="boothsContainer">{this.state.booths}</div>
          </motion.div>
        )}
        <div class="circle" onClick={this.newBooth}>
          <div class="horizontal-plus"></div>
          <div class="vertical-plus"></div>
        </div>
      </div>
    );
  }
}

const wrapperVariants = {
  inActive: {
    scale: 0,
    y: -50
  },
  active: {
    scale: 1,
    y: 0
  }
};

export class Booth extends Component {
  constructor(props) {
    debugger;
    super(props);
    this.state = {
      isToggled: false,
      loading: false
    };
  }

  componentDidMount() {
    this.setState({
      title: this.props.booth.title,
      description: this.props.booth.description,
      code: this.props.booth.code,
      event: this.props.event,
      index: this.props.booth.index,
      id: this.props.booth._id
    });
  }

  componentWillUnmount() {
    debugger;
  }

  updateBooth = formEvent => {
    const { title, description, code, index, event, id } = this.state;
    f.check("/events/booth/update", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      },
      body: JSON.stringify({
        event: event,
        index: index,
        code: code,
        title: title,
        description: description,
        id: id
      })
    }).then(response => {
      response.json().then(data => {
        debugger;
      });
    });
    //       });
  };

  deleteBooth = () => {
    debugger;
    const { title, description, code, index, event, id } = this.state;
    this.props.deleteBooth(title, description, code, index, event, id);
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    console.log(this.props.index);
  };

  render() {
    const { title, description, code, id } = this.state;

    return (
      <div>
        <div class="boothContainer">
          <div>
            <div style={styles.boothBox}>
              <div class="deleteButton" onClick={this.deleteBooth}>
                <div class="deleteText">X</div>
              </div>
            </div>

            <div style={styles.boothBox}>
              <div class="idWrapper">
                <div class="id">Id: {id}</div>
              </div>
            </div>
            <div class="boothBox">
              <div></div>

              <div style={styles.titleBox}>
                <div style={{ textAlign: "center" }}>
                  <textarea
                    name="title"
                    id="styled"
                    value={title}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Title"
                  />
                </div>
              </div>
              <div class="barcodeBox" style={styles.barcodeBox}>
                <div style={styles.crossUp}>
                  <div style={styles.crossFlat}>
                    <div style={styles.outlineScanner} />
                  </div>
                </div>
              </div>

              <div class="descriptionBox">
                <div>
                  <textarea
                    style={{ height: 100 }}
                    name="description"
                    value={description}
                    onChange={this.onChange}
                    type="text"
                    placeholder="Description"
                  />
                </div>
              </div>
            </div>

            <div style={styles.boothBox}>
              <div class="submitButton" onClick={this.updateBooth}>
                Update
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

let styles = {
  container: {
    flex: 1,
    justifyContent: "center"
  },
  barcodeBox: {
    display: "flex",
    flex: 3,
    paddingTop: 10,
    paddingBottom: 10,
    width: width,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0,.7)"
  },
  titleBox: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  boothBox: {
    display: "flex",
    flex: 2,
    alignItems: "flex-start",
    justifyContent: "center",
    textAlign: "left",
    overflow: "scroll"
  },
  title: {
    fontSize: 35,
    color: "white"
  },
  title2: {
    color: "white"
  },
  rectangle: {
    alignItems: "center",
    justifyContent: "center"
  },
  rectangle2: {
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1
  },
  outline: {
    width: width * 0.75,
    height: width * 0.75,
    borderColor: "red",
    borderWidth: 5,
    alignItems: "center"
  },
  animation: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  barcodeScanner: {
    width: width * 0.6,
    height: width * 0.6,
    justifyContent: "center",
    alignItems: "center"
  },
  outlineScanner: {
    margin: 30,
    width: width * 0.68,
    height: width * 0.68,
    borderRadius: 20,
    backgroundColor: "rgba(255, 255, 255,1)",
    justifyContent: "center",
    alignItems: "center"
  },
  outlineScanner2: {
    borderWidth: 20,
    borderRadius: 40,
    width: width * 0.67,
    height: width * 0.67,
    zIndex: 86,
    borderColor: "rgba(255, 255, 255,1)",
    justifyContent: "center",
    alignItems: "center"
  },
  backgroundImage: {
    flex: 1
  },
  crossUp: {
    backgroundColor: "rgba(255, 255, 255,.7)",
    height: width * 0.85,
    width: width * 0.155,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30
  },
  crossFlat: {
    backgroundColor: "rgba(255, 255, 255,.7)",
    display: "flex",
    height: width * 0.155,
    width: width * 0.85,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30
  },
  button: {
    backgroundColor: "rgba(255, 255, 255,.7)",
    width: width * 0.8,
    height: "75%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 20
  }
};

export default BoothPage;
