import React,  {Component, useState } from 'react';
import { Link } from "react-router-dom";
import SignOutButton from "../SignOut";
import "./nav.css";
import * as ROUTES from "../../constants/routes";
import { AuthUserContext } from "../Session";
import Core from "../Home/core";
import { motion } from "framer-motion";



const navVariants = {

  active: {
    backgroundColor: 'rgba(255,255,255,1)',
    boxShadow: '0px 0px 5px black',
  },
  inActive: {
    backgroundColor: 'rgba(255,255,255,0)',
    boxShadow: '0px 0px 0px black',
  }

}

const textVariants = {
  active: {
    color: 'rgba(124,10,1)'
  },
  inActive: {
    color: 'rgba(255,255,255)'
  } 
}


const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {({ authUser }) =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);
export class NavigationAuth extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isToggled: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  handleScroll = () => {
    if (window.pageYOffset > 60) {
      this.setState({isToggled: true  })
    } else {
      this.setState({isToggled: false})
    }
  

  };

  render() {
    return (
<div>
    <motion.ul
    animate={this.state.isToggled ? "active" : "inActive"}
    variants={navVariants}
    transition={{ duration: 0.5 }}
    class="navBar">
    <div class="navLogoBox">
    <img class="navLogo" src={require('../../img/HSA-Red.png')} alt="Log In"/>
    </div>

    <div class="navButtonsBox">
      <li class="navButton">
        <Link class="navLink" to={ROUTES.HOME}>
        
          <motion.div
            animate={this.state.isToggled ? "active" : "inActive"}
            variants={textVariants}
            transition={{ duration: 0.2 }} 
            class="navBox">
            Home
            </motion.div>
        </Link>
      </li>
      <li class="navButton">
        <Link class="navLink" to={ROUTES.EVENTS}>
        <motion.div
            animate={this.state.isToggled ? "active" : "inActive"}
            variants={textVariants}
            transition={{ duration: 0.2 }} 
            class="navBox">
            Events
            </motion.div>
        </Link>
      </li>
      <li class="navButton">
        <Link class="navLink" to={ROUTES.MEMBERS}>
        <motion.div
            animate={this.state.isToggled ? "active" : "inActive"}
            variants={textVariants}
            transition={{ duration: 0.2 }} 
            class="navBox">
            Members
            </motion.div>
        </Link>
      </li>
      <li class="navButton">
        <Link class="navLink" to={ROUTES.ACCOUNT}>
        <div class="dropdown">
        <div class="dropbtn">Account</div>
        <div class="dropdown-content">
          <a href="#">Link 1</a>
          <a href="#">Link 2</a>
          <SignOutButton/>
        </div>
      </div>
        </Link>
      </li>
      </div>
    </motion.ul>
    {/* <Core /> */}
  </div>


    )
  }


  
};
const NavigationNonAuth = () => (
  <div></div>
);

export default Navigation;
