import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { PasswordForgetLink } from "../PasswordForget";
import { withFirebase } from "../Firebase";
import * as firebase from "firebase";
import { motion, AnimatePresence } from "framer-motion";
import * as admin from "firebase-admin";
import * as ROUTES from "../../constants/routes";
import AuthUserContext from "../Session/context";
import qs from "qs";
import './landing.css';
import f from "../Session/fetchWrapper";

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInInitiated: false,
      access_token: "",
      code: "",
      codeReceived: false,
      loading: false,
      authurl: "https://slack.com/oauth/authorize?scope=channels:read,channels:write,groups:read,chat:write:user,users.profile:read,users:read&client_id=646538230018.900656055094&redirect_uri=",
      redirect_uri: "",
      signingIn: true,
      authStatus: false,

    };
  }


  componentDidMount() {
    this.setState({loading: true})
    this.getUserAsync();

  }

  handleStatus(authStatus) {
    if (authStatus) {
      this.props.history.push(ROUTES.HOME)
    } else {
      this.setState({loading: false})
    }
  }

  async getUserAsync() {
    var authStatus = true;
    debugger;
      await fetch(`https://app.longhornhsa.org/users/me`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "text/plain"
        }
      })
      .then(response => {
        if (response.status === 401) {
          authStatus = false;
          this.handleStatus(authStatus)

        } else {
          authStatus = true;
          this.handleStatus(authStatus)
          }
        });
    debugger;
    console.log(authStatus)
    return authStatus;
  }

  checkAuthStatus() {
    fetch("http://app.longhornhsa.org/users/me", 
    {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "text/plain"
      }
    }).then(response => {
      debugger;
      if (response.status === 401) {
        debugger;
        this.setState({authStatus: false});
      } else {
        this.setState({authStatus: true});
      }  
    })
    .then(() => {
      if (this.state.authStatus) {
        this.props.history.push(ROUTES.HOME)
      } else {
        this.setState({loading: false})
      }
    })
  }

  fetchData(code, redirect_uri) {
    var data = {
      client_id: "646538230018.900656055094",
      client_secret: "13d9916cf5fca75f4b13d2840b40ab18",
      code: code,
      redirect_uri: redirect_uri
    };
    var name = "";
    var slackId = "";
    var token = "";
    var requestUrl = this.buildURL("https://slack.com/api/oauth.access", data);
    console.log(requestUrl);
    fetch(requestUrl, {
      method: "POST"
    }).then(response => {
      response
        .json()
        .then(data => {
          console.log(data);
          slackId = data.user_id;
          token = data.access_token;
        })
        .then(() => this.signIn(slackId, token));
    });
  }

  buildURL(url, params) {
    if (params == null) return url;

    const serializedParams = qs.stringify(params);
    if (!serializedParams) return url;

    return `${url}${url.indexOf("?") < 0 ? "?" : "&"}${serializedParams}`;
  }

  render() {
    return (
      <div class="bg-img">
        <div class="container">
        <motion.div
            animate={{
            opacity:[0,1],
            scale: [.6, 1],
            y: [0, 0],
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [0, 1]
            }}
            >
        <div class="signInBox">
        <div class="logoBox">
        <img class="loginLogo" src={require('../../img/HSA-Red.png')} alt="Log In"/>
        </div>
            
        <div>
          {this.state.loading ?
            <div>loading</div>
            

          :
          <AnimatePresence>
          {this.state.signingIn ?
          <motion.div
          key="signIn"
          initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: '100%' }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [0, 1]
            }}
            exit={{ opacity: 0, scale: 0, height: 0 }}
          
          >
          <SignInForm />
          <div style={{color: 'black'}}>
            Don't have an account? <button onClick={() => this.setState({signingIn: !this.state.signingIn})}>Sign Up</button>
          </div>
          </motion.div> 
          
          :
          <motion.div
          key="signup"
          initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: '100%' }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [0, 1]
            }}
            exit={{ opacity: 0, scale: 0, height: 0 }}
          
          >
          <SignUpForm />
          <div style={{color: 'black'}}>
          Already have an account? <button onClick={() => this.setState({signingIn: !this.state.signingIn})}>Sign In</button>
          </div>
            </motion.div>
          }
          </AnimatePresence>
          }
          
          
        </div>
        </div>
        </motion.div>
        </div>
      </div>
    );
  }
}
const INITIAL_STATE = {
  email: "",
  password: "",
  error: null
};


class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = (updateAuthUser) => {
    const { email, password } = this.state;
    fetch("https://app.longhornhsa.org/users/login", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      },
      body: JSON.stringify({ email: email, password: password })
    })
      .then(response => {
        debugger;
        this.setState({ ...INITIAL_STATE });
        response.json().then(data => {
          debugger;
          updateAuthUser(data);
        });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === "" || email === "";
    return (
      <AuthUserContext.Consumer>
        {({ authUser, updateAuthUser }) => (
          <form class="form" id="signIn">
          <div class="inputBox">
            <input
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address"
            />
            </div>
            <div class="inputBox">
            <input
              name="password"
              value={password}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            </div>
            <div onClick={() => this.onSubmit(updateAuthUser)} class="signInButton">
              <div class="signInText">Sign In</div>
            </div>
            {error && <p>{error.message}</p>}
          </form>
        )}
      </AuthUserContext.Consumer>
    );
  }
}
const SignInForm = compose(withRouter)(SignInFormBase);
export default SignInPage;
export { SignInForm };

const INITIAL_STATE_SIGNUP = {
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  error: null
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE_SIGNUP };
  }
  onSubmit = (updateAuthUser) => {
    const { username, email, passwordOne } = this.state;

    fetch("https://app.longhornhsa.org/users/", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      },
      body: JSON.stringify({
        name: username,
        email: email,
        password: passwordOne
      })
    })
    .then(response => {
      debugger;
      this.setState({ ...INITIAL_STATE_SIGNUP });
      response.json().then(data => {
        debugger;
        updateAuthUser(data);
      });
      this.props.history.push(ROUTES.HOME);
    })
      .catch(error => {
        this.setState({ error });
      });
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;
    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      username === "";
    return (
      <AuthUserContext.Consumer>
        {({ authUser, updateAuthUser }) => (
          <form class="form" >
          <div class="inputBox">
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="Full Name"
        />
        </div>
        <div class="inputBox">
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        </div>
        <div class="inputBox">
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
        />
        </div>
        <div class="inputBox">
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="Confirm Password"
        />
        </div>
        <div class="signInButton" onClick={() => this.onSubmit(updateAuthUser)}>
          <div class="signInText">Sign Up</div>
        </div>
        {error && <p>{error.message}</p>}
      </form>
      )}
      </AuthUserContext.Consumer>
    );
  }
}
const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);

const SignUpForm = compose(withRouter)(SignUpFormBase);
export { SignUpForm, SignUpLink };

