import React, { Component, useState } from "react";
import "./home.css";
import { withFirebase } from "../Firebase";
import * as firebase from "firebase";
import { Link, Route, Redirect } from "react-router-dom";
import { motion } from "framer-motion";
import CoreImage from "./CoreImage";
import f from "../Session/fetchWrapper"

const wrapperVariants = {
  inActive: {
    scale: 0.6,
    x: 40,
    height: 150,
    y: -200
  },
  active: {
    scale: 1,
    x: 0,
    height: 495,
    y: 0
  }
};
class Core extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      members: [],
      isToggled: "false"
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    var members = [];
    f.check("/members/core", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/JSON"
      }
    })
      .then(response => {
        console.log(response);
        response.json().then(data => {
          const memberData = Object.values(data);
          for (var i = 0; i < memberData.length; i++) {
            console.log(memberData[i]);
            members.push(memberData[i]);
          }
        });
      })
      .then(() =>
        this.setState({
          members: members,
          membersLoaded: true
        })
      );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  toggleZoom() {
    this.setState({ isToggled: !this.state.isToggled });
  }

  scrollTop() {
    this.coreTitle.scrollIntoView({ behavior: "smooth" });
  }

  hideZoom() {
    this.setState({ isToggled: "false" }, () => this.scrollTop());
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    let core = [];
    const coreMembers = this.state.members;
    for (var i = 0; i < coreMembers.length; i++) {
      var firstName = coreMembers[i].first;
      var lastName = coreMembers[i].last;
      var name = firstName + " " + lastName;
      var position = coreMembers[i].position;
      var phone = coreMembers[i].phone;
      var email = coreMembers[i].email;
      core.push(
        <CoreMember
          name={name}
          position={position}
          phone={phone}
          email={email}
          firstName={firstName}
          lastName={lastName}
        ></CoreMember>
      );
    }

    return (
      <motion.div
        animate={this.state.isToggled ? "inActive" : "active"}
        onHoverStart={() => this.toggleZoom()}
        onHoverEnd={() => this.hideZoom()}
        variants={wrapperVariants}
        transition={{ duration: 0.5 }}
        class="membersWrapper"
        id="coreWrapper"
      >
        <div
          ref={el => {
            this.coreTitle = el;
          }}
          class="coreTitle"
        >
          <div class="coreTitleBox">Core</div>
        </div>
        {core}
      </motion.div>
    );
  }
}

const pictureVariants = {
  active: {
    scale: 1,
    borderTopLeftRadius: "90px",
    borderBottomLeftRadius: "90px",
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
    rotate: 0
  },
  inActive: {
    borderTopLeftRadius: "90px",
    borderBottomLeftRadius: "90px",
    borderTopRightRadius: "90px",
    borderBottomRightRadius: "90px",
    rotate: -360
  }
};

const infoVariants = {
  active: {
    opacity: 1,
    width: 300,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "column"
  },
  inActive: {
    opacity: 0,
    width: 0,
    padding: 0,
    paddingLeft: 0,
    paddingRight: 0,
    flexDirection: "row"
  }
};

const buttonVariants = {
  active: {
    opacity: 1,
    width: 100
  },
  inActive: {
    opacity: 0,
    width: 0,
    padding: 0
  }
};

const transition = { duration: 0.5 };

export class CoreMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      expanded: "false",
      isToggled: "false"
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  toggleMember() {
    this.setState({ isToggled: !this.state.isToggled });
    console.log(this.state.expanded);
  }

  hideMember() {
    this.setState({ isToggled: "false" });
  }

  render() {
    return (
      <div
        class="memberBox"
        onClick={() => this.toggleMember()}
        onMouseLeave={() => this.hideMember()}
      >
        <motion.div
          animate={this.state.isToggled ? "inActive" : "active"}
          variants={pictureVariants}
          transition={transition}
          class="pictureBox"
        >
          <CoreImage
            style={styles.corePicture}
            firstName={this.props.firstName}
          />
        </motion.div>
        <motion.div
          animate={this.state.isToggled ? "inActive" : "active"}
          variants={infoVariants}
          transition={transition}
          class="infoBox"
        >
          <div class="infoBg">
            <div class="infoContent">
              <div class="infoLine">
                <div class="info">
                  <div class="name">{this.props.name}</div>
                </div>
              </div>
              <div class="position">{this.props.position}</div>
              <div class="position">{this.props.phone}</div>
              <div class="position">{this.props.email}</div>
            </div>
          </div>
        </motion.div>
        <motion.div
          animate={this.state.isToggled ? "inActive" : "active"}
          variants={buttonVariants}
          transition={transition}
        >
          <Link
            class="navLink"
            to={`/core/${this.props.first + this.props.last}`}
          >
            <div class="buttonBox">
              <div class="buttonBg">
                <div class="buttonContent">+</div>
              </div>
            </div>
          </Link>
        </motion.div>

        <Route
          path={`/users/${this.props.name}`}
          //render={(props) => <BoothPage event={id} eventName={name} {...props} /> }
        />
      </div>
    );
  }
}

let styles = {
  corePicture: {
    height: 145,
    display: "flex",
    // borderTopLeftRadius: 90,
    // borderBottomLeftRadius: 90,
    borderRadius: 90,
    borderColor: "#fffff0"
  }
};

export default withFirebase(Core);
