import React, { Component } from "react";
import "../Home/home.css";
const data = [
  {
    address: "908 Poplar St, APT 209",
    birthday: "2019-01-12T06:00:00.000Z",
    car: "Zipcar",
    email: "Abhaykram12@gmail.com",
    first: "Abhay",
    last: "Ram",
    phone: 8476121211,
    position: "Media Chair",
    shirtSize: "M"
  },
  {
    address: "2505 San Gabriel St. Apt 408",
    birthday: "Feb",
    car: "Y",
    email: "ajaygadwal@utexas.edu",
    first: "Ajay",
    last: "Gadwal",
    phone: "4697348098",
    position: "General Core",
    shirtSize: "M"
  },
  {
    address: "2501 Pearl St, Apt 415",
    birthday: "2019-05-27T05:00:00.000Z",
    car: "N",
    email: "vgapurva@utexas.edu",
    first: "Apurva",
    last: "Gunturu",
    phone: "(469) 506-9567",
    position: "Financial Director",
    shirtSize: "M"
  },
  {
    address: "2300 Nueces St., Apt. 807",
    birthday: "2019-10-12T05:00:00.000Z",
    car: "N",
    email: "avanibellary@gmail.com",
    first: "Avani",
    last: "Bellary",
    phone: "(469) 358-6784",
    position: "Vice President",
    shirtSize: "M"
  },
  {
    address: "908 Poplar St, APT 209",
    birthday: "2019-05-23T05:00:00.000Z",
    car: "Y",
    email: "Chittari.ganesh@gmail.com",
    first: "Ganesh",
    last: "Chittari",
    phone: 8326280447,
    position: "Diwali Co-Chair",
    shirtSize: "M"
  },
  {
    address: "2100 Nueces St. Apt. 1015-C",
    birthday: "2019-02-27T06:00:00.000Z",
    car: "N",
    email: "gauree.srini@gmail.com",
    first: "Gauree",
    last: "Srinivasan",
    phone: "(469) 301-5138",
    position: "Diwali Co-Chair",
    shirtSize: "S"
  },
  {
    address: "2300 Nueces St. Apt. 229",
    birthday: "2019-09-16T05:00:00.000Z",
    car: "N",
    email: "hiranbuyyana@gmail.com",
    first: "Hiranmayee",
    last: "Buyyanapragada",
    phone: "(214) 471-2791",
    position: "HAW Co-Chair",
    shirtSize: "S"
  },
  {
    address: "702 W 22nd st Unit D",
    birthday: "2019-05-02T05:00:00.000Z",
    car: "Y",
    email: "jp52868@utexas.edu",
    first: "Jay",
    last: "Patel",
    phone: "(210) 701-6104",
    position: "Membership Director",
    shirtSize: "S"
  },
  {
    address: "2300 Nueces St., Apt. 518",
    birthday: "2019-02-25T06:00:00.000Z",
    car: "N",
    email: "jigar.patel225@gmail.com",
    first: "Jigar",
    last: "Patel",
    phone: "(972) 689-9135",
    position: "President",
    shirtSize: "M"
  },
  {
    address: "2101 Rio Grande St apt 11007",
    birthday: "2019-12-18T06:00:00.000Z",
    car: "Access (zip car)",
    email: "kiranabass@utexas.edu",
    first: "Kiran",
    last: "Abass",
    phone: "(305) 746-6439",
    position: "General Core",
    shirtSize: "M"
  },
  {
    address: "2501 Pearl St. apt 415",
    birthday: "2019-01-19T06:00:00.000Z",
    car: "Y",
    email: "msgatt.2017@gmail.com",
    first: "Mansi",
    last: "Gattani",
    phone: "9189604252",
    position: "Holi Co-Chair",
    shirtSize: "M"
  },
  {
    address: "2624 Whitis Ave #LLA 207",
    birthday: "2019-02-24T06:00:00.000Z",
    car: "Y (at will)",
    email: "irrinki16@gmail.com",
    first: "Neha",
    last: "Irrinki",
    phone: "(512) 906-7588",
    position: "Navratri Co-Chair",
    shirtSize: "M"
  },
  {
    address: "2501 Whitis Ave.",
    birthday: "2019-02-05T06:00:00.000Z",
    car: "N",
    email: "sanjna_bhatia@yahoo.com",
    first: "Sanjna",
    last: "Bhatia",
    phone: "(214) 690-3474",
    position: "Meetings Chair",
    shirtSize: "M"
  },
  {
    address: "2101 Rio Grande St. Apt 11007",
    birthday: "2019-12-17T06:00:00.000Z",
    car: "N",
    email: "sanskritiag@utexas.edu",
    first: "Sanskriti",
    last: "Agarwal",
    phone: "(214) 517-3946",
    position: "Navratri Co-Chair",
    shirtSize: "S or XS "
  },
  {
    address: "2505 San Gabriel St. Apt 408",
    birthday: "2019-02-08T06:00:00.000Z",
    car: "N",
    email: "shiv6281@gmail.com",
    first: "Shiv",
    last: "Patel",
    phone: 8324146281,
    position: "General Core",
    shirtSize: "M"
  },
  {
    address: "4305 Duval st. Apt.310",
    birthday: "2019-03-17T05:00:00.000Z",
    car: "N",
    email: "soumyakovela@utexas.edu",
    first: "Soumya",
    last: "Kovela",
    phone: "(817)-617-9289",
    position: "HAW Co-Chair",
    shirtSize: "L"
  },
  {
    address: "2101 Rio Grande St apt 11011",
    birthday: "2019-12-10T06:00:00.000Z",
    car: "Y",
    email: "tvinayagam2@gmail.com",
    first: "Thiru",
    last: "Vinayagam",
    phone: "(225) 333-6108",
    position: "General Core",
    shirtSize: "M"
  },
  {
    address: "714 W 22nd St Apt 502",
    birthday: "2019-05-07T05:00:00.000Z",
    car: "N",
    email: "vxm4872@gmail.com",
    first: "Varshni",
    last: "Muralidharan",
    phone: "(214) 546-4245",
    position: "Holi Co-Chair",
    shirtSize: "S"
  }
];

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    console.log(data);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    return (
      <div>
        <div class="boothContainer">
          <h1>Welcome</h1>
        </div>
      </div>
    );
  }
}

export default LandingPage;
