import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "./app.css";
import Navigation from "../Navigation";
import HomePage from "../Home";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import PasswordForgetPage from "../PasswordForget";
import EventPage from "../Events";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import LandingPage from "../Landing";
import Core from "../Home/core";
import Container from "./Container"

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";
const App = () => (
  <Router>
    <div>
      <div style={{ position: "fixed", width: "100%", zIndex: 1 }}>
        <Navigation />
      </div>
      <div>
      <Container />
      </div>
      
    </div>
  </Router>
);
export default withAuthentication(App);
