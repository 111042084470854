import React, { Component } from "react";
import { NavLink, Route, Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import './event.css';
import BoothPage from "../Booth";
import { withAuthorization, AuthUserContext } from "../Session";
class EventPage extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    return (
      <div>
        <div class="eventBg">
        <div class="eventContainer">
          <h1>Events</h1>
          <ul class="eventBar">
          
          {events.map(({ name, id }) => (
              <li class="navButton" key={id}>
                <NavLink
                  activeClassName="eventBox-active"
                  className="eventBox"
                  to={`/events/${id}`}
                >
                  <div style={{ width: this.state.width / 6 }}>
                    <div>{name}</div>
                  </div>
                </NavLink>
              </li>
            ))}
            
          </ul>
          <div style={{minHeight: '80vh'}}>
          {events.map(({ name, id }) => (
            <Route
              path={`/events/${id}`}
              render={props => (
                <BoothPage event={id} eventName={name} {...props} />
              )}
            />
          ))}
          </div>
          </div>
        </div>
      </div>
    );
  }
}

const events = [
  {
    name: "Navratri",
    id: "navratri",
    description: "dancing",
    resources: [{}, {}]
  },
  {
    name: "Diwali",
    id: "diwali",
    description: "lights",
    resources: [{}, {}]
  },
  {
    name: "Sankranti",
    id: "sankranti",
    description: "kites",
    resources: [{}, {}]
  },
  {
    name: "Hinduism Awareness Week",
    id: "haw",
    description: "Week of Awareness",
    resources: [{}, {}]
  },
  {
    name: "Holi",
    id: "holi",
    description: "Lotta Colors",
    resources: [{}, {}]
  },
  {
    name: "Kids Camp",
    id: "kidscamp",
    description: "Kids woo",
    resources: [{}, {}]
  }
];

let styles = {
  
  titleBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center"
  },
  eventBox: {
    height: 350,
    width: 350,
    color: "white",
    backgroundColor: "rgba(124,10,20,10)",
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: 10,
    fontSize: 25
  },
  navLink: {
    textDecoration: "none",
    display: "block",
    color: "white",
    textAlign: "center",
    padding: 16,
    textDecoration: "none"
  },
  navBox: {
    width: 60,
    height: 60,
    backgroundColor: "blue"
  },
  eventBar: {
    listStyleType: "none",
    overflow: "hidden",
    backgroundColor: "#333333",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%"
  }
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(EventPage);
