import React from "react";
import { withRouter } from "react-router-dom";
import f from "./fetchWrapper";
import { compose } from "recompose";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      f.check("/users/me", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "text/plain"
        }
      }).then(response => {
        if (response.status === 401) {
          //        this.props.history.push(ROUTES.SIGN_IN)
        } else {
          debugger;
          response.json().then(data => {
            debugger;
          });
        }
      });
    }
    componentWillUnmount() {}
    render() {
      return (
        <AuthUserContext.Consumer>
          {({ authUser, updateAuthUser }) =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }
  return compose(withRouter, withFirebase)(WithAuthorization);
};
export default withAuthorization;
