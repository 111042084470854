import React from "react";
import { withFirebase } from "../Firebase";
import "../Navigation/nav.css";

function SignOut() {
  fetch("https://app.longhornhsa.org/users/me/logout", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/JSON"
    }
  }).then(() => window.location.reload());
}

const SignOutButton = history => (
  <div class="navLink">
    <button class="signOutButton" type="button" onClick={SignOut}>
      Sign Out
    </button>
  </div>
);

export default SignOutButton;
