import React from "react";
import * as firebase from "firebase";

export default class UserImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      mounted: true,
      image: "/images/HSALogo.jpg",
      url: ""
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.getAndLoadHttpUrl();
  }

  async getAndLoadHttpUrl() {
    if (this.state.mounted == true) {
      var firstName = this.props.firstName;
      const ref = firebase
        .storage()
        .ref("images/headshots/" + firstName + ".jpg");
      ref
        .getDownloadURL()
        .then(data => {
          this.setState({ url: data });
          this.setState({ loading: false });
        })
        .catch(error => {
          const ref = firebase.storage().ref("images/HSACircle.jpg");
          ref.getDownloadURL().then(data => {
            this.setState({ url: data });
            this.setState({ loading: false });
          });
        });
    }
  }
  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  componentWillReceiveProps(props) {
    this.props = props;
    if (this.props.refresh == true) {
    }
  }

  render() {
    if (this.state.mounted == true) {
      if (this.state.loading == true) {
        return (
          <div
            key={this.props.image}
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <div>Loading</div>
          </div>
        );
      } else {
        return <img alt="oops" style={this.props.style} src={this.state.url} />;
      }
    } else {
      return null;
    }
  }
}
