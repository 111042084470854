import React, { Component, Fragment } from "react";
import "./home.css";
import Core from "./core";
import { AuthUserContext, withAuthorization } from "../Session";
import Select from "react-select";
import { motion } from "framer-motion";
import qs from "qs";

const calendar_configuration = {
    api_key: 'AIzaSyDp3Xsa2WMK5ScoBxYUXol9g4lQPrGGENI',
    calendars: [
      {
        name: 'HSA',
        url: 'p6bjfbeastf4psn71vhqs6vhu0@group.calendar.google.com'
      }
    ],
    dailyRecurrence: 700,
    weeklyRecurrence: 500,
    monthlyRecurrence: 20
}


class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      loading: true,
      membersLoaded: false,
      channelsLoaded: false,
      members: [],
      channels: [],
      currentChannel: "",
      message: "",
      userToken: "",
      events: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    debugger;
    fetch(`https://app.longhornhsa.org/google/main/calendar`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/JSON"
        }
      })    
      .then(response => response.json())
      .then(data => {
        var eventData = data.events;
        console.log(eventData)
        var eventArray = [];
        eventData.forEach(element => {
          console.log(element);
          try {
            var startDate = new Date(element.start.dateTime);
          }
          catch {

          }
          try {
            var endDate = new Date(element.end.dateTime);
          }
          catch {

          }
          var name = element.summary;
          eventArray.push({
            name: name,
            startDate: startDate,
            endDate: endDate,
          })
        })

        var currentDay = eventArray.filter(function (el) {
          var today = new Date()
          today.setHours(0,0,0,0);
          return el.startDate >= today;
        });

        this.setState({events: currentDay})
        console.log(currentDay);
  })
}


  fetchChannels(token) {
    var channels = [];
    var data = {
      token: token,
      exclude_archived: true,
      types: "private_channel"
    };
    var requestUrl = this.buildURL(
      "https://slack.com/api/conversations.list",
      data
    );
    console.log(requestUrl);
    fetch(requestUrl, {
      method: "GET"
    })
      .then(response => {
        response.json().then(data => {
          var channelsParsed = [];
          console.log(data.channels);
          var channelData = data.channels;
          for (var i = 0; i < channelData.length; i++) {
            channels.push({
              value: channelData[i].id,
              label: channelData[i].name
            });
          }
        });
        console.log(channels);
      })
      .then(() => this.setState({ channels: channels }));
  }
  buildURL(url, params) {
    if (params == null) return url;

    const serializedParams = qs.stringify(params);
    if (!serializedParams) return url;

    return `${url}${url.indexOf("?") < 0 ? "?" : "&"}${serializedParams}`;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleChange = currentChannel => {
    this.setState({ currentChannel });
    console.log(`Option selected:`, currentChannel);
  };

  onSubmit = () => {
    const { message, userToken, currentChannel } = this.state;
    var data = {
      token: userToken,
      channel: currentChannel.value,
      text: message,
      as_user: true
    };
    console.log(message);
    console.log(userToken);
    console.log(currentChannel.value);
    var requestUrl = this.buildURL(
      "https://slack.com/api/chat.postMessage",
      data
    );
    console.log(requestUrl);
    fetch(requestUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Basic " + { userToken },
        "Access-Control-Allow-Credentials": true,
        "Access-Control-Allow-Headers": true
      }
    })
      .then(response => {
        response.json().then(data => {
          console.log(data);
        });
      })
      .then(() => this.setState({ message: "" }));
  };

  onChange = event => {
    this.setState({ message: event.target.value });
  };

  render() {
    const { message, userToken, currentChannel, events } = this.state;
    return (
      <div>
        <div class="homeBg">
        <div class="homeContainer">
          <EventWrapper events={events}/>
        <div>

          
        </div>
           </div>
          </div>
      </div>
    );
  }
}


class EventWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: this.props.events,
    }
  }
  render() {
    const {events} = this.props;
    console.log(events)
    return (
      <Fragment>

      <div class="calEvWrapper">
      <h1>Upcoming Events</h1>
      {events.map((event => (
        <Event event={event}/>
        )))}
      </div>

      </Fragment>
      
      
    )

  }



}

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: this.props.event,
      name: this.getName(this.props.event.name),
    }
  }
  

  getName(name) {
    if (name.length > 24){
      return name.substring(0,22) + '...';
    } else {
      return name
    }
  }
  render() {
    const {event} = this.state;
    console.log(event)
    
    return (
      <div style={{display: 'flex'}}>
<div class="dailyBox">
          <div class="calEvTitle">{this.state.name}</div>
        <div>{event.startDate.toLocaleTimeString()}</div>
        <div>{event.endDate.toLocaleTimeString()}</div> 
      </div>
      <div class="dateBox">
      {event.startDate.toLocaleDateString()}
      </div>
      </div>
      
      
    )

  }
  
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(HomePage);
