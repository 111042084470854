import React from "react";
import f from "./fetchWrapper";
import AuthUserContext from "./context";

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        updateAuthUser: this.updateAuthUser
      };
      this.updateAuthUser = this.updateAuthUser.bind(this);
    }

    componentDidMount() {
      fetch("https://app.longhornhsa.org/users/me", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "text/plain"
        }
      }).then(response => {
        debugger;
        if (response.status === 401) {
          debugger;
          this.setState({ authUser: null });
        } else {
          debugger;
          response.json().then(data => {
            this.setState({ authUser: data });
          });
        }
      });
    }

    updateAuthUser = authUser => {
      this.setState({ authUser: authUser });
    };

    componentWillUnmount() {}

    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }
  return WithAuthentication;
};
export default withAuthentication;
